import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../Constant";
import "../css/SlidingImages.css";
import first from '../Assets/Photos/1.jpg'
import second from '../Assets/Photos/2.jpg'
import third from '../Assets/Photos/3.jpg'
import fourth from '../Assets/Photos/4.jpg'
import fifth from '../Assets/Photos/5.jpg'
import sixth from '../Assets/Photos/A.jpg'
//import seventh from '../Assets/Photos/A1.jpg'
import eight from '../Assets/Photos/A2.jpg'
import ninth from '../Assets/Photos/A3.jpg'
import tenth from '../Assets/Photos/A4.jpg'
import eleventh from '../Assets/Photos/A5.jpg'
export default function SlidingImages() {
  const [productData, setProductData] = useState([]);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/Dashboard/school/SchoolActivities`
        );

        setProductData(response.data.ProductData);
        console.log("Fetched product data:", response.data.ProductData);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="marquee">
        <div className="marquee-content right-to-left">
          {/* {productData.length> 0 && productData.map((item) => (
            <div className="marquee-item" key={item._id}>
              <img style={{height:'150px',borderRadius:'10px'}}
                className="img"
                src={`${BASE_URL}/${item.productImage}`}
                alt={item.productDetails}
              />
            </div>
          ))} */}
          <div className="marquee-item" >
              <img style={{height:'200px',width:"300px",borderRadius:'10px'}}
                className="img"
                src={first}
              />
            </div>

            <div className="marquee-item" >
              <img style={{height:'200px',width:"300px",borderRadius:'10px'}}
                className="img"
                src={second}
              />
            </div>

            <div className="marquee-item" >
              <img style={{height:'200px',width:"300px",borderRadius:'10px'}}
                className="img"
                src={eight}
              />
            </div>

            <div className="marquee-item" >
              <img style={{height:'200px',width:"300px",borderRadius:'10px'}}
                className="img"
                src={eleventh}
              />
            </div>
        </div>
      </div>
    </div>
  );
}
